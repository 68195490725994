document.addEventListener("turbolinks:load", () => {
  var cities, country, escaped_estado, mensaje, options_cities, options_states, state, states;
  country = $(`#${gon.model}_country_id :selected`).text();
  states = $(`#${gon.model}_state_id`).html();
  mensaje = "First select the country";
  options_states = $(states).filter("optgroup[label='" + country + "']").html();
  $(`#${gon.model}_state_id`).html(states);
  $(`#${gon.model}_country_id`).change(function() {
    country = $(`#${gon.model}_country_id :selected`).text();
    options_states = $(states).filter("optgroup[label='" + country + "']").html();
    if (options_states) {
      return $(`#${gon.model}_state_id`).html(options_states);
    } else {
      return $(`#${gon.model}_state_id`).empty();
    }
  });
  state = $(`#${gon.model}_state_id :selected`).text();
  cities = $(`#${gon.model}_city_id`).html();
  escaped_estado = state.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, '\\$1');
  $(`#${gon.model}_state_location_id`).html(state);
  options_cities = $(cities).sort().filter("optgroup[label='" + state + "']").html();
  $(`#${gon.model}_city_id`).html(options_cities);
  return $(`#${gon.model}_state_id`).change(function() {
    $(`#${gon.model}_city_id`).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100);
    state = $(`#${gon.model}_state_id :selected`).text();
    escaped_estado = state.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, '\\$1');
    options_cities = $(cities).sort().filter("optgroup[label='" + state + "']").html();
    if (options_cities) {
      $(`#${gon.model}_city_id`).html(options_cities);
      return $(`#${gon.model}_state_location_id`).html(state);
    } else {
      return $(`#${gon.model}_city_id`).empty();
    }
  });
});
