// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("jquery")
require("@nathanvda/cocoon")
require("@popperjs/core")
import "bootstrap"
import "../stylesheets/application"
// import { Modal } from 'bootstrap'
import { Tooltip, Popover } from 'bootstrap'
require("prefacturas")
require("location")
require("estudiantes")
import 'bootstrap-icons/font/bootstrap-icons.css'




$(document).ready(function(){
  // Turn on js-selectable class so that it becomes SELCT 2 tag
  $('.js-searchable').select2({
    allowClear: true,
    width: 200
    // If you are using Bootstrap, please add　`theme: "bootstrap"` too.
  });
});
 
import flatpickr from "flatpickr";
document.addEventListener("turbolinks:load", function () {

  $('.table-responsive').on('show.bs.dropdown', function () {
     $('.table-responsive').css( "overflow", "inherit" );
  });

  $('.table-responsive').on('hide.bs.dropdown', function () {
       $('.table-responsive').css( "overflow", "auto" );
  });

  flatpickr("#datepicker", {
    altInput: true,
    altFormat: "j F, Y",
    minDate: new Date().fp_incr(-20075),
    maxDate: new Date().fp_incr(-5840)
  });
  flatpickr("#convenio_inicio", {
    altInput: true,
    altFormat: "j F, Y",
    minDate: new Date().fp_incr(gon.user ? -15: -365),
    maxDate: new Date().fp_incr(gon.user ? 60 : 365)
  });
  flatpickr("#convenio_final", {
    altInput: true,
    altFormat: "j F, Y",
    minDate: new Date().fp_incr(gon.user ? 15 : 1),
    maxDate: new Date().fp_incr(gon.user ? 242 : 365)
  });
  flatpickr("#convenio_terminacion", {
    altInput: true,
    altFormat: "j F, Y",
    minDate: new Date("<%= @convenio.fecha_de_inicio %>"),
    maxDate: new Date("<%= @convenio.fecha_de_termino %>")
  });

  jQuery(function () {
    // alert(123456)
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    })

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new Popover(popoverTriggerEl)
    })
  })

  
  
});

