document.addEventListener("turbolinks:load", () => {

    $('#new_prefactura').on('keypress', e => {
        if (e.keyCode == 13) {
            return false;
        }
    });

    
   
    const cargoo = document.querySelector(`#cargo-administrativo`).innerHTML;
    console.log(`1 cargoo = ${cargoo}`);
    const cargo = cargoo/100;
    console.log(`2 cargo = ${cargo}`);

    let dispersado = 0;
    let seguros = 0;
    let beca;
    let dias;
    let dispersado_field = document.querySelector(`#prefactura_monto_a_dispersar`);
    let cargo_por_seguros_field = document.querySelector(`#prefactura_cargo_por_seguros`);
    let cargo_administrativo_field = document.querySelector(`#prefactura_cargo_administrativo`);
    let subtotal_field = document.querySelector(`#prefactura_subtotal`);
    let iva_field = document.querySelector(`#prefactura_iva`);
    let total_field = document.querySelector(`#prefactura_total`);


    
    


    function dias_del_periodo() {
        let esquema_de_pago = document.querySelector(`#esquema-de-pago`).innerHTML;
        if (esquema_de_pago == 'QUINCENAL - 15 Y ÚLTIMOS DE MES' || 
            esquema_de_pago == 'QUINCENAL - 12 Y 27' || esquema_de_pago == 'QUINCENAL - 10 Y 25'){
           return 15;
        } else if (esquema_de_pago == 'SEMANAL - CADA VIERNES' || 
            esquema_de_pago == 'SEMANAL - CADA SABADO' ||
            esquema_de_pago == 'SEMANAL - VIERNES DESFASADO' || 
            esquema_de_pago == 'SEMANAL - CADA JUEVES'){
            return 7;
        } else if (esquema_de_pago == 'CATORCENAL - CADA 2 VIERNES' || 
            esquema_de_pago == 'CADA 2 SEMANAS'){
            return 14;
        } else if (esquema_de_pago == 'MENSUAL - ÚLTIMOS DE MES' || 
            esquema_de_pago == 'MENSUAL - DIAS 20'){
            return 30;  
        } else if (esquema_de_pago == 'SEMANAL - CADA VIERNES - 5 - DIAS'){
            return 5;
        } else if (esquema_de_pago == 'SEMANAL - CADA SABADO - 4 - DIAS'){
            return 4;
        } else if (esquema_de_pago == 'CATORCENAL - CADA 2 VIERNES - 10 DIAS' || 
            esquema_de_pago == 'CATORCENAL - MIERCOLES'){
            return 10;
        } else {
            0
        }
    }
    


    

    function calculo_de_filas(){
        let table = document.getElementById("estudiantes_en_prefactura");
        let rowCount = table.rows.length - 1;
        return rowCount;
    }

    function calculo_de_becas() {
        let table = document.getElementById("estudiantes_en_prefactura");
        let rowCount = calculo_de_filas();
        let dias_periodo = dias_del_periodo();
        dispersado = 0;
        seguros = 0;
        let counter = 0;
        while (counter < rowCount) {

            let seguro_field = document.querySelector(`#seguro_${CSS.escape(counter)}`).innerHTML;
            let seguro = parseInt(seguro_field);
            console.log(`3 seguro = ${seguro}`);
            let cargo_de_seguro = document.querySelector(`#prefactura_dispersions_attributes_${CSS.escape(counter)}_seguro`);
            cargo_de_seguro.defaultValue = seguro;

            let beca_field = document.querySelector(`#prefactura_dispersions_attributes_${CSS.escape(counter)}_beca_hidden`);
            let beca = parseInt(beca_field.value);
            console.log(`4 beca = ${beca}`);
            let dias_field = document.querySelector(`#prefactura_dispersions_attributes_${CSS.escape(counter)}_dias_a_pagar`);
            let dias = parseInt(dias_field.value);
            console.log(`5 dias = ${dias}`);
            let ajuste_field = document.querySelector(`#prefactura_dispersions_attributes_${CSS.escape(counter)}_ajuste`);
            let ajuste = parseInt(ajuste_field.value*100);
            console.log(`6 ajuste = ${ajuste}`);
            let dispersion_field = document.querySelector(`#prefactura_dispersions_attributes_${CSS.escape(counter)}_monto_a_dispersar`);                  
            //ajuste_field.defaultValue = ajuste;
            console.log(`7 dias_del_periodo = ${dias_del_periodo()}`);
            let calculo = Math.trunc(( beca / dias_periodo * dias) + ajuste);
            if (calculo < 0){
                calculo = 0
            }
            dispersion_field.defaultValue = "$" + (calculo/100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            counter += 1;
            dispersado += calculo;

            seguros += seguro; 
        }
        
    }

    function totales(){
        
        dispersado_field.defaultValue = "$" + (dispersado/100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

        cargo_por_seguros_field.defaultValue = "$" + (seguros).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        cargo_administrativo_field.defaultValue = "$" + (dispersado/100*cargo).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        subtotal_field.defaultValue = "$" + ( dispersado/100 + seguros + dispersado*cargo/100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        iva_field.defaultValue = "$" + (( dispersado/100 + seguros + dispersado*cargo/100)*.16).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        total_field.defaultValue = "$" + (( dispersado/100 + seguros + dispersado*cargo/100)*1.16).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

    }
    calculo_de_becas();
    
    totales();

    $('#estudiantes_en_prefactura tr td').on('input', function() {
        calculo_de_becas();
        totales();
    });



    
    



    
    
    
});

